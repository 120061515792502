@import url(https://fonts.googleapis.com/css?family=Righteous);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  display: inline-block;
  color: white;
  font-family: 'Righteous', serif;
  font-size: 4.5em;
  text-shadow: 0.03em 0.03em 0 hsla(230, 40%, 50%, 1);
}

.main {
  text-align: center;
  height: 100vh;
  background-color: rgb(28, 190, 131);
  font-family: sans-serif;
  background-image: url(https://wallpaperboat.com/wp-content/uploads/2020/08/08/51893/anime-scenery-05.jpg);
}

.result {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.box-result {
  background-color: #1251b0;
  transition: box-shadow 0.25s;
  padding: 15px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 10px;
  color: white;
  font-family: 'Righteous', serif;
}

.box-result-score {
  background-color: #f40076;
  width: 25%;
  font-size: 1.2rem;
}

.picks {
  height: 25vh;
  display: flex;
  justify-content: center;
}

.computer {
  width: 400px;
  margin-top: 2rem;
}

.player {
  width: 400px;
  transform: rotateY(180deg);
  margin-top: 2rem;
}

.button {
  width: 150px;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  background-color: coral;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.img-weapon {
  max-width: 110px;
  height: auto;
}

.choice {
  margin-top: 4rem;
}

.select-choice {
  display: inline-block;
  border: 5px solid #000;
  border-radius: 80px;
  margin: 1rem;
  margin-top: 12rem;
  padding: 0.6rem;
  transition: all 0.6s ease;
}

#rock {
  background-color: #fe8;
  box-shadow: 5px 7px 8px #888;
}

#paper {
  background-color: #f40076;
  box-shadow: 5px 7px 8px #888;
}

#scissors {
  background-color: #75c9c8;
  box-shadow: 5px 7px 8px #888;
}

#rock:hover,
#paper:hover,
#scissors:hover {
  background-color: #a6a6a6;
  cursor: pointer;
}

.score {
  padding: 50px;
}

@media (max-width: 480px) {
  h1 {
    font-size: 3rem;
  }

  .box-result {
    padding: 5px;
  }

  .box-result-score {
    width: auto;
  }

  .result {
    gap: 1rem;
  }

  .picks {
    margin-top: 5rem;
  }

  .choice {
    margin-top: -5rem;
  }

  .img-weapon {
    max-width: 60px;
  }

  .mti {
    margin-left: -50rem;
  }
}
